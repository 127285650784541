input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.textereference{
  display: flex;
  justify-content: center ;

}

.souscadre{
  display: flex;
  flex-direction: column;
  width: 500px;
}

.textereferenceT{
  color: black;
  font-size: 70%;

}

.lettre{
  font-size: 170%;
  font-weight: bold;
  margin-left: 20px;
}

.lettreG{
  font-size: 200%;
  font-weight: bold;
  margin-left: 20px;
}

.titrelocchimio{
  font-size: 170%;
  margin-bottom: 10px;
  font-weight: bold;
  color: #241f56;
  border: none;
  border-color: #6194ed;
  text-decoration: underline;
}

.pagemenu{
  display: flex;
  flex-direction: row ;
  margin-top: 35px;

}

.menugauche{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start; 
  width: 66%;
}

.menudroite{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start; 
  width: 33%;
}

.ligne{
  display: flex;
  flex-direction:row ;
  min-width: 100%;
  margin-bottom: 35px;
}

.lg{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start ;
  align-items: center ; 
  min-width: 50%;

}

.ld{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start ;
  align-items: center ; 
  min-width: 50%;
}

.lx{
  display: flex;
  flex-direction:column ;
  justify-content:  flex-start ;
  align-items: center ; 
  min-width: 100%;
}

.FR{
  color: white;
  margin-left: 10px;
  z-index: 10;
  float: left;
  position: fixed;
  margin-top: 12px;

}

@media all and (max-width:480px) {
  .FR{
    display: none;
    color: black;
    /* margin-top: 70px; */
    margin-left: 10px;
    z-index: 10;
    position:relative;
    
  }

}

@media all and (max-height:480px) {
  .FR{
    display: none;
    color: black;
    margin-left: 10px;
    /* margin-top: 70px; */
    z-index: 10;
    position:relative;
  
  }

}

.textedetail{
  text-align: justify;
}

.detail{
  color: white;
  margin-left: 750px;
  z-index: 10;
  float: left;
  position: fixed;
  margin-top: 48px;
}

@media all and (max-width:480px) {
  .detail{
    display: none;
    color: black;
    /* margin-top: 70px; */
    margin-left: 10px;
    z-index: 10;
    position:relative;
    
  }

}

@media all and (max-height:480px) {
  .detail{
    display: none;
    color: black;
    margin-left: 10px;
    /* margin-top: 70px; */
    z-index: 10;
    position:relative;
  
  }

}


.detailfr{
  color: white;
  margin-left: 750px;
  z-index: 10;
  float: left;
  position: fixed;
  margin-top: 48px;
}

@media all and (max-width:480px) {
  .detailfr{
    display: none;
    color: black;
    /* margin-top: 70px; */
    margin-left: 10px;
    z-index: 10;
    position:relative;
    
  }

}

@media all and (max-height:480px) {
  .detailfr{
    display: none;
    color: black;
    margin-left: 10px;
    /* margin-top: 70px; */
    z-index: 10;
    position:relative;
  
  }

}


.FRmobile{
display: none;
}

@media all and (max-width:480px) {
  .FRmobile{
display: inline;

  }

}

@media all and (max-height:480px) {
  .FRmobile{
    display: inline;
 
  }

}

.btnFRmobile{
  background: transparent;
  border: none !important;
  color: black;
  font-weight: bold;
  /* position: fixed; */

}

.btnFR{
  background: transparent;
  border: none !important;
  color: white;
  position: fixed;

}


@media all and (max-width:480px) {
  .btnFR{
    background: transparent;
    border: none !important;
    color: black;
    position: fixed;
  }

}

@media all and (max-height:480px) {
  .btnFR{
    background: transparent;
    border: none !important;
    color: black;
    position: fixed;
  
  }

}


.logo{
  position: absolute;
  float: right
}


/* @media all and (min-height:480px) and (min-width:480px) {
  .FRmobile{
    display: none;
  
  }

} */




.logopageaccueil{
  width: 12%;
  height: 8%;
}

.logolien{
  width: 100%;
  height: 100%;
}



.divlien {
  display: flex;
  align-items: center ;
  justify-content: center ;
  height: 100%;
  width: 100%;
}

a.lienaccueil {
  text-decoration: none;
  color: #1F3356;
  font-size: 2em;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center ;
  justify-content: center ;

}


a:link {

}

a:visited {

}

a:focus {

}

a.lienaccueil:hover {
  color: #F4F8FC;
  background-color: #1F3356;
  text-decoration: none;
}

a:active {

}

a.lienonco {
  display: flex;
  text-decoration: none;
  color: #1F3356;
  font-size: 110%;
  display: flex;
  align-items: center ;
  justify-content: center ;
  margin-top: 0px;
  height: 40px;
  min-width: 100%;
}

a.lienonco:hover{
  color: #F4F8FC;
  background-color: #1F3356;
  text-decoration: none;
}

.divlien{
    /* border-style: solid; */
    width: 31%;
}

.divpageacceuil {
  margin-top: 100px;
  /* border-style: solid; */
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
  height: auto;
  min-height: 550px;
}

.divdroite {
  /* border-style: solid; */
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-around; 
}

.divpagetexte {
  /* border-style: solid; */
  width: 49%;
}

.divpagelien {
  /* border-style: solid; */
  height: 49%;
}

.divpageexemple {
  /* border-style: solid; */
  height: 49%;
}

.divpagelien {
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
}

.textev1 {
  margin-bottom: 15px;
  color: #1F3356;
  font-family: 'Poppins', sans-serif;
  font-size: 3em;
  font-weight: bold;
}

.textev2 {
  margin-bottom: 10px;
  color: #70768A;
  font-family: 'Poppins', sans-serif;
  font-size: 1,7em;
}

a:hover {
  background-color: #262626;
}

@media all and (max-width:480px) {

  a:hover {
    background-color: #F5F5F7;
  }
}

@media all and (max-height:480px) {
  a:hover {
    background-color: #262626;
  }

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.champ {
  height: auto;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  min-height: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #F4F8FC;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
/* } */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
ONCO PAGE */

.divpageonco {
  display: flex;
  flex-direction:row ;
  justify-content: space-around; 
  margin-top: 70px;
  min-height: 900px;
}

.divoncocolonne{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center ;
  justify-content: center ;
  font-size: 130%;
  font-weight: bold;


}



.titrecolonne{
  margin-bottom: 20px;
  font-size: 145%;
  /* border-style: solid;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px; */
}

/* PAGE CS */

.App {
  display: flex;
  flex-direction:column ;

  background-color: #F5F5F7;
  color: #1F3356;
  min-height: 100%;
  height: 100%;
  max-height: none;
  margin: 0px;
  padding: 0px;
}

.Header{
  display: flex;
  flex-direction:row-reverse ;
  justify-content: space-between;
position: fixed;
width: 100%;
z-index: 1;
height: 87px;
background-color: #262626;
/* opacity: 0,9; */

}

@media all and (max-width:480px) {
  .Header{
    display: flex;
    flex-direction:column ;
  position: fixed;
  width: 100%;
  z-index: 1;
  height: 87px;
  background-color: #FFFFFF;
  /* opacity: 0,9; */
  
  }

}

@media all and (max-height:480px) {
  .Header{
    display: flex;
    flex-direction:row-reverse ;
  position: fixed;
  width: 100%;
  z-index: 1;
  height: 80px;
  background-color: #FFFFFF;
  /* opacity: 0,9; */
  
  }

}

.logoines{
  display :block;

}

@media all and (max-width:480px) {
  .logoines{
    display :none;
  
  }

}

@media all and (max-height:480px) {
  .logoines{
    display :block;
  
  }

}

.logoinesmobile{
  display :none;

}

@media all and (max-width:480px) {
  .logoinesmobile{
    display :block;
  
  }

}

@media all and (max-height:480px) {
  .logoinesmobile{
    display :none;
  
  }

}


.Headertexte{
  color: white;
  font-family: sans-serif;
  font-size: large;
  margin-left: 10px;

}

.textlien {
  /* display: flex;
  align-items: flex-end; */
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  height: 100%;
  width: 100%;
  color: #AC2306;
  margin-right: 30px;
  margin-top: 50px;
}

@media all and (max-width:480px) {
  .textlien {
    /* display: flex;
    align-items: flex-end; */
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    height: 30%;
    width: 100%;
    color: #AC2306;
    margin-right: 30px;
    margin-top: 0px;
    margin-bottom: 25px;
    text-align: center;
    background-color: #F5F5F7;
  }

}

@media all and (max-height:480px) {
  .textlien {
    /* display: flex;
    align-items: flex-end; */
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    height: 100%;
    width: 100%;
    color: #AC2306;
    margin-right: 30px;
    margin-top: 50px;
  }

}


.bio{
  
}

.titrepartie{
  font-family: sans-serif;
  font-size: 130%;
  font-weight: bold;
  margin-bottom: 25px;
  margin-left: 20px;
  color: #262626;
  /* text-decoration: underline; */
}

@media all and (max-width:480px) {
  .titrepartie{
    font-family: sans-serif;
    font-size: 130%;
    font-weight: bold;
    margin-bottom: 25px;
    margin-left: 5px;
    color: #262626;
    /* text-decoration: underline; */
  }

}

@media all and (max-height:480px) {
  .titrepartie{
    font-family: sans-serif;
    font-size: 130%;
    font-weight: bold;
    margin-bottom: 25px;
    margin-left: 25px;
    color: #262626;
    /* text-decoration: underline; */
  }

}


.texteeliminatoire{
  font-family: sans-serif;
  font-size: 100%;
  font-weight: bold;
  color: #910018;
  margin-top: 15px;
}

.droite{
  /* border-style: solid; */
  display: flex;
  max-width: 44%;
  min-width: 40%;
  min-height: 100%;
  overflow: hidden;
  margin-top: 15px;

}
@media all and (max-width:480px) {
  .droite{
    /* border-style: solid; */
    display: flex;
    max-width: 44%;
    min-width: 40%;
    min-height: 100%;
    overflow: hidden;
    /* margin-top: 40px; */
  
  }

}

@media all and (max-height:480px) {
  .droite{
    /* border-style: solid; */
    display: flex;
    max-width: 44%;
    min-width: 40%;
    min-height: 100%;
    overflow: hidden;
    /* margin-top: 40px; */
  
  }

}


@media all and (max-width:480px) {
  .droite{
    /* border-style: solid; */
    display: flex;
    min-width: 100%;
    height: 100%;
    /* overflow: hidden; */
    /* margin-top: 40px; */

  }

}

@media all and (max-height:480px) {
  .droite{
    /* border-style: solid; */
    display: flex;
    min-width: 100%;
    height: 100%;
    /* overflow: hidden; */
    /* margin-top: 40px; */

  }

}

.gauche{
  display: flex;
  flex-direction:column ;
  justify-content: space-around; 
  width: 60%;
  overflow: hidden;
}

@media all and (max-width:480px) {
  .gauche{
    display: flex;
    flex-direction:column ;
    justify-content: space-around; 
    width: 100%;
    overflow: hidden;
  }
}

@media all and (max-height:480px) {
  .gauche{
    display: flex;
    flex-direction:column ;
    justify-content: space-around; 
    width: 100%;
    overflow: hidden;
  }
}

.droiterectangle{
  display: flex;
  flex-direction:column ;
  font-weight: bold;
  position: fixed;
  height: 92%;
  width: 50%;
  overflow-y: hidden;
  border: red;
  font-size: 13px;  
}

@media all and (max-width:480px) {
  .droiterectangle{
    display: flex;
    flex-direction:column ;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position:absolute;
    height: 90%;
    min-width: 100%;
    /* overflow-y: hidden; */
    border: red;
    font-size: 13px;  
    /* border-style: double; */
  }

}
@media all and (max-height:480px) {
  .droiterectangle{
    display: flex;
    flex-direction:column ;
    font-weight: bold;
    position:absolute;
    height: 110%;
    min-width: 100%;
    /* overflow-y: hidden; */
    border: red;
    font-size: 13px;  
    /* border-style: double; */
  }

}

.textescore{
  font-family: sans-serif;
  font-size: 110%;
  /* color: #262626; */
  color: #262626;
  /* border: solid; */
}



@media all and (max-width:480px) {

  .textescore{
    font-family: sans-serif;
    font-size: 83%;
    color: #262626;
    /* border: solid; */
  
  }
}

@media all and (max-height:480px) {
  .textescore{
    font-family: sans-serif;
    font-size: 110%;
    color: #262626;
    /* border: solid; */
  
  }

}

.texteposition{
  font-family: sans-serif;
  font-size: 80%;
  /* color: #262626; */
  color: #262626;
  /* border: solid; */
}



@media all and (max-width:480px) {

  .texteposition{
    font-family: sans-serif;
    font-size: 60%;
    color: #262626;
    /* border: solid; */
  
  }
}

@media all and (max-height:480px) {
  .texteposition{
    font-family: sans-serif;
    font-size: 80%;
    color: #262626;
    /* border: solid; */
  
  }

}


.nomscore{
  display: flex;
  flex-direction:column ;
  justify-content: center;
  align-items: center;
  font-size: 130%;
  color: #851d2a;
  text-align: center;
}

.nomssolution{
  display: flex;
  flex-direction:column ;
  justify-content: center;
  align-items: center;
  font-size: 150%;
  color: black;
}

@media all and (max-width:480px) {
  .nomscore{
    display: flex;
    flex-direction:column ;
    justify-content: center;
    align-items: center;
    font-size: 130%;
    color: #AC2306;
    
  }

}

@media all and (max-height:480px) {
  .nomscore{
    display: flex;
    flex-direction:column ;
    justify-content: center;
    align-items: center;
    font-size: 130%;
    color: #AC2306;
    
  }

}

.textescoretotal{
  font-family: sans-serif;
  font-size: 130%;
  color: #262626;
}

.score20{
  font-size: large;
  align-items: center ;
}

.score20tot{
  font-size: 170%;
}

.score{
  display: flex;
  justify-content: center;
  flex-direction:column ;
  overflow-y: hidden;
  border-style:solid;
  border-width: 7px;
  border-radius: 20px;
  /* border-width: 90%; */
  /* border-color: #F5F5F7; */
  background-color: #F5F5F7;
  width: 80%;
  height: 75%;
  color: #262626;

}

@media all and (max-width:480px) {
  .score{
    display: flex;
    justify-content: center;
    flex-direction:column ;
    overflow-y: hidden;
    border-style:solid;
    border-width: 7px;
    border-radius: 20px;
    /* border-width: 90%; */
    /* border-color: #F5F5F7; */
    background-color: #F5F5F7;
    width: 100%;
    height: 100%;
    color: #262626;
  
  }

}

@media all and (max-height:480px) {
  .score{
    display: flex;
    justify-content: center;
    flex-direction:column ;
    overflow-y: hidden;
    border-style:solid;
    border-width: 7px;
    border-radius: 20px;
    /* border-width: 90%; */
    /* border-color: #F5F5F7; */
    background-color: #F5F5F7;
    width: 100%;
    height: 105%;
    color: #262626;
  
  }

}

.scoreinfo{
  display: flex;
  flex-direction:row ;
  overflow: hidden;
  justify-content: center;
  /* border-style: double; */
}
.scorehiden{
  display: none;
}

@media all and (max-width:480px) {
  .scoreinfo{
    display: flex;
    flex-direction:row ;
    overflow: hidden;
    justify-content: center;
    min-height: 170px;
    margin-top: 5px;
  }

}

@media all and (max-height:480px) {
  .scoreinfo{
    display: flex;
    flex-direction:row ;
    overflow: hidden;
    justify-content: center;
    min-height: 50%;
    /* border-style: double; */
  }

}


.scoregauche{
  display: flex;
  flex-direction:column ;
  justify-content: space-around; 
  width: 45%;
  overflow: hidden;
  margin-top: 30px;
  margin-left: 10px;
}

@media all and (max-width:480px) {
  .scoregauche{
    display: flex;
    flex-direction:column ;
    justify-content: space-around; 
    width: 45%;
    overflow: hidden;
    margin-top: 0px;
    margin-left: 5px;
    /* border-style: double; */
  }

}

@media all and (max-height:480px) {
  .scoregauche{
    display: flex;
    flex-direction:column ;
    justify-content: space-around; 
    width: 45%;
    height: 100%;
    overflow: hidden;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    /* border: solid; */
  }

}




.scoredroite{
  display: flex;
  flex-direction:column ;
  justify-content: space-around; 
  width: 45%;
  margin-top: 30px;
  margin-left: 30px;
}

@media all and (max-width:480px) {
  .scoredroite{
    display: flex;
    flex-direction:column ;
    justify-content: space-around; 
    width: 45%;
    overflow: hidden;
    margin-top: 0px;
    margin-left: 5px;
  }

}

@media all and (max-height:480px) {
  .scoredroite{
    display: flex;
    flex-direction:column ;
    justify-content: space-around; 
    width: 45%;
    height: 100%;
    margin-left: 30px;
    margin-top: 0px;
    /* border: solid; */
    margin-bottom: 0px;
  }

}

.popup{
margin-left: 100px;

}

@media all and (max-width:480px) {
  .popup{
    margin-left: 0px;
  }

}

@media all and (max-height:480px) {
  .popup{
    margin-left: 130px;
  }

}

.popupintra-content {
  background: rgb(255, 255, 255);
  width: 100%;
  padding: 5px;
}



.infoappli{
text-align: center;
/* border: solid; */
width: 98%;
height: 70px;
font-size: 110%;
  }

  @media all and (max-width:480px) {
    .infoappli{
      text-align: center;
      /* border: solid; */
      width: 100%;
      /* height: 70px; */
      height: 50%;
      font-size: 110%;
        }

  }

  @media all and (max-height:480px) {
    .infoappli{
      text-align: center;
      /* border: solid; */
      width: 98%;
      height: 50px;
      font-size: 110%;
        }

  }


.infoeval{
  /* border: solid; */
  text-align: center;
  width: 98%;
  height: 70px;
  /* margin-top: 10px; */
  font-size: 110%;
  margin-top: 20px;
  }

  @media all and (max-width:480px) {
    .infoeval{
      /* border: solid; */
      text-align: center;
      width: 100%;
      /* height: 70px; */
      height: 40%;
      /* margin-top: 10px; */
      font-size: 110%;
      margin-top: 20px;
      }

  }

  @media all and (max-height:480px) {
    .infoeval{
      /* border: solid; */
      text-align: center;
      width: 98%;
      height: 50px;
      /* margin-top: 10px; */
      font-size: 110%;
      margin-top: 0px;
      margin-bottom: 0px;
      }

  }


.infoediteur{
  text-align: center;
  /* border: solid; */
  width: 98%;
  height: 70px;
  font-size: 110%;
  margin-top: 20px;
}

@media all and (max-width:480px) {
  .infoediteur{
    text-align: center;
    /* border: solid; */
    width: 100%;
    /* height: 70px; */
    height: 40%;
    font-size: 110%;
    margin-top: 20px;
  }

}

@media all and (max-height:480px) {
  .infoediteur{
    text-align: center;
    /* border: solid; */
    width: 98%;
    height: 50px;
    font-size: 110%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

}


.infocible{
  text-align: center;
  /* border: solid; */
  width: 98%;
  height: 70px;
  font-size: 110%;
}

@media all and (max-width:480px) {
  .infocible{
    text-align: center;
    /* border: solid; */
    width: 100%;
    /* height: 70px; */
    height: 50%;
    font-size: 110%;
  }

}

@media all and (max-height:480px) {
  .infocible{
    text-align: center;
    /* border: solid; */
    width: 98%;
    height: 70px;
    font-size: 110%;
  }

}


.scoretotal{
  /* border: solid; */
  text-align: center;
  margin-top: 20px;
  min-width: 100%;
  height: 100px;
  font-size: 150%;  
  display: flex;
  /* justify-content: center; */
  flex-direction: column;

}

@media all and (max-width:480px) {
  .scoretotal{
    /* border: solid; */
    text-align: center;
    margin-top: 0px;
    min-width: 100%;
    height: 80px;
    font-size: 150%;  
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
  
  }

}

@media all and (max-height:480px) {
  .scoretotal{
    /* border: solid; */
    text-align: center;
    margin-top: 0px;
    min-width: 100%;
    height: 100px;
    font-size: 150%;  
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
  
  }

}
  
  /* 
  font-weight: bold;
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 92%;
  width: 50%;
  overflow: scroll;
  border: red;
  font-size: 13px;   
  */
  



.gaucherectangle{
  display: flex;
  flex-direction: column;
  justify-content:center;
  /* border-style: solid; */
}


.droitegauche{
  margin-top: 100px;
  display: flex;
  flex-direction:row ;
  justify-content: space-around; 
  max-height: 100%;
  min-height: 100%;
  /* height: 91%; */
  overflow: hidden;
}


@media all and (max-width:480px) {
  .droitegauche{
    margin-top: 90px;
    display: flex;
    flex-direction:column ;
    /* justify-content: space-around;  */
    /* max-height: 100%;
    min-height: 100%; */
    /* height: 91%; */
    /* overflow: hidden; */
  }
}

@media all and (max-height:480px) {
  .droitegauche{
    margin-top: 90px;
    display: flex;
    flex-direction:column ;
    /* justify-content: space-around;  */
    /* max-height: 100%;
    min-height: 100%; */
    /* height: 91%; */
    /* overflow: hidden; */
  }
}

.droitegauchebio{
  margin-top: 0px;
  display: flex;
  flex-direction:row ;
  justify-content: space-around; 
  max-height: 100%;
  min-height: 100%;
  /* height: 91%; */
  overflow: hidden;
  background-color: #DF6511;
}

@media all and (max-width:480px) {

  .droitegauchebio{
    margin-top: 0px;
    display: flex;
    flex-direction:row ;
    justify-content: space-around; 
    height: 100%;
    /* height: 91%; */
    overflow: hidden;
    background-color: #DF6511;
  }
}

@media all and (max-height:480px) {
  .droitegauchebio{
    margin-top: 0px;
    display: flex;
    /* flex-direction:row ;
    justify-content: space-around;  */
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* height: 91%; */
    overflow: hidden;
    background-color: #DF6511;
  }

}


.pagebio{
  max-height: 1000px;
  min-height: 1000px;
  background-color: #DF6511;
}

@media all and (max-width:480px) {
  .pagebio{
    height: 100%;
    background-color: white;
    zoom: 42%;
    margin-top: 20px;
  }
  

}

@media all and (max-height:480px) {
  .pagebio{
    height: 100%;
    background-color: #DF6511;
    zoom: 60%;
    display: flex;
    flex-direction:row ;
    justify-content:center;
  }

}




.gauchesection{
display: flex;
flex-direction:row ;
justify-content: flex-start;
/* border-style: solid; */
margin-bottom: 25px;
margin-left: 5px;
}

@media all and (max-width:480px) {
  .gauchesection{
    display: flex;
    flex-direction:column ;
    justify-content: center;
    /* border-style: solid; */
    margin-bottom: 25px;
    margin-left: 4px;
    }

}

@media all and (max-height:480px) {
  .gauchesection{
    display: flex;
    flex-direction:column ;
    justify-content: center;
    /* border-style: solid; */
    margin-bottom: 25px;
    margin-left: 5px;
    }

}



.gauchesectionfinal{
  display: flex;
  flex-direction:row ;
  justify-content: flex-start;
  /* border-style: solid; */
  margin-bottom: 25px;
  }

  @media all and (max-width:480px) {

    .gauchesectionfinal{
      display: flex;
      flex-direction:row ;
      justify-content: flex-start;
      /* border-style: solid; */
      /* margin-bottom: 25px; */
      }
  }

  @media all and (max-height:480px) {

    .gauchesectionfinal{
      display: flex;
      flex-direction:row ;
      justify-content: flex-start;
      /* border-style: solid; */
      /* margin-bottom: 25px; */
      }
  }

.gauche100{
  /* border-style: solid; */
  width: 99%;  
  margin-right: 10px;
}

.gauche66{
  /* border-style: solid; */
  width: 65%;  
  margin-right: 10px;
}

.gauche50{
  display: flex;
  flex-direction:column ;
  width: 49%;  
  /* border-style: solid; */
  margin-right: 10px;
}

.gauche33{
  display: flex;
  flex-direction:column ;
  width: 32%; 
  /* border-style: solid;  */
  margin-right: 10px;
}

.gauche37{
  display: flex;
  flex-direction:column ;
  width: 37%; 
  /* border-style: solid;  */
  margin-right: 10px;
}
.gauche16{
  display: flex;
  flex-direction:column ;
  width: 16%; 
  /* border-style: solid;  */
  margin-right: 10px;
}

.gauche25{
  display: flex;
  flex-direction:column ;
  width: 24%; 
  /* border-style: solid;  */
  margin-right: 10px;
}

.gauche14{
  display: flex;
  flex-direction:column ;
  width: 14%; 
  /* border-style: solid;  */
  margin-right: 10px;
  
}

.gauche20{
  display: flex;
  flex-direction:column ;
  width: 20%; 
  /* border-style: solid;  */
  margin-right: 10px;
  
}


.titreacc{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #024722;
  font-size: 1,5em;
}

.accordion{
  width: 100%;
  margin-bottom: 20px;

}

.accordionfinal{
  width: 100%;
  margin-bottom: 20px;

}

@media all and (max-width:480px) {

  .accordionfinal{
    width: 100%;
    /* margin-bottom: 20px; */
  
  }
}

@media all and (max-height:480px) {

  .accordionfinal{
    width: 100%;
    /* margin-bottom: 20px; */
  
  }
}

.titreitem{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #477D9F;
  font-size: 1,2em;
  text-decoration: underline;
  margin-top: 20px;
}

/* // BOUTON */

.btn-info {
  /* border-color: #1F3356 !important; */
  background-color: #f2eee6 !important;
  /* background-color: red !important; */
  color: #262626 !important;
  min-width: 100%;
  margin-bottom: 4px;
  height: 32px;
  border-color: #262626 !important;
  /* display: flex; */
  text-align : left !important;
  height: 100%;
}

@media all and (max-width:480px) {
  .btn-info {
    /* border-color: #1F3356 !important; */
    background-color: #f2eee6 !important;
    /* background-color: red !important; */
    color: #262626 !important;
    min-width: 100%;
    margin-bottom: 4px;

    border-color: #262626 !important;
    /* display: flex; */
    text-align : left !important;
    min-height: 32px;
    height: 100%;
    font-size: 90% !important;
  }
}

@media all and (max-height:480px) {
  .btn-info {
    /* border-color: #1F3356 !important; */
    background-color: #f2eee6 !important;
    /* background-color: red !important; */
    color: #262626 !important;
    min-width: 100%;
    margin-bottom: 4px;

    border-color: #262626 !important;
    /* display: flex; */
    text-align : left !important;
    min-height: 32px;
    height: 100%;
    font-size: 90% !important;
  }
}



.btn-danger {
  min-width: 100%;
  margin-bottom: 4px;
  height: 32px;
  height: 100%;
}

.btn-outline-danger {
  color: #910018 !important;
  font-weight: bold !important;
}

.btn-outline-danger:hover {
  background-color: #910018 !important;
  color: white !important;
  font-weight: bold !important;
}

/* SI non cochable */
.btn-secondary {
  background-color: #f2eee6 !important;
  color: #c9c9c9 !important;
  min-width: 100%;
  margin-bottom: 4px;
  height: 32px;
  border-color: #262626 !important;
  display: flex;
  align-items: start;
  text-align : left !important;
  height: 100%;
}

@media all and (max-width:480px) {
  .btn-secondary {
    background-color: #f2eee6 !important;
    color: #c9c9c9 !important;
    min-width: 100%;
    margin-bottom: 4px;
    border-color: #262626 !important;
    display: flex;
    align-items: start;
    text-align : left !important;
    min-height: 32px;
    height: 100%;
    font-size: 90% !important;
  }

}

@media all and (max-height:480px) {
  .btn-secondary {
    background-color: #f2eee6 !important;
    color: #c9c9c9 !important;
    min-width: 100%;
    margin-bottom: 4px;
    border-color: #262626 !important;
    display: flex;
    align-items: start;
    text-align : left !important;
    min-height: 32px;
    height: 100%;
    font-size: 90% !important;
  }

}



.btn-info:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-success {
  background-color: #851d2a!important;
  color: #faf7f2 !important;
  min-width: 100%;
  margin-bottom: 4px;
  border-color: #262626 !important;
  height: 32px;
  display: flex;
  align-items: start;
  text-align : left !important;
  height: 100%;
}

@media all and (max-width:480px) {
  .btn-success {
    background-color: #851d2a!important;
    color: #faf7f2 !important;
    min-width: 100%;
    margin-bottom: 4px;
    border-color: #262626 !important;
    display: flex;
    align-items: start;
    text-align : left !important;
    min-height: 32px;
    height: 100%;
    font-size: 90% !important;
  }

}

@media all and (max-height:480px) {
  .btn-success {
    background-color: #851d2a!important;
    color: #faf7f2 !important;
    min-width: 100%;
    margin-bottom: 4px;
    border-color: #262626 !important;
    display: flex;
    align-items: start;
    text-align : left !important;
    min-height: 32px;
    height: 100%;
    font-size: 90% !important;
  }

}

.btn-success:focus {
  outline: none !important;
  box-shadow: none !important;
}

input.champ{
  min-width: 100%;
  min-height: 35px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

input.number1{
  max-width: 50%;
  max-width: 50%;
  min-height: 23px;
  max-height: 23px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

input.date1{
  max-width: 75%;
  max-width: 75%;
  min-height: 23px;
  max-height: 23px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

input.input1{
  min-width: 100%;
  min-height: 23px;
  max-height: 23px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

p.titrebouton{
  display: flex;
  justify-content: center ;
  color: #262626;
  font-weight: bold;
  font-style: italic;
}


.inputbioligne{
  display: flex;
  flex-direction: row ;
  max-height: 35px;
  align-items: center ;
}

.texteinputbio{
  margin-top: 10px;
  margin-right: 5px;
}

.texteinputbiogras{
  margin-top: 10px;
  margin-right: 5px;
  font-weight: bold;
}

.lignebio{
  /* display: flex;
  flex-direction: row ; */
}

textarea.champ{
  min-width: 100%;
  min-height: 50px;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
}

textarea.zonetextedroite{
  display: flex;
  min-width: 90%;
  max-width: 90%;
  min-height: 90%;
  max-height: 90%;
  margin-bottom: 4px;
  border-color: #1F3356;
  border-width: 1px;
  color: #1f2a56;
  
}



/* SELECTOR */
/* 
.selector{
  display: flex;
  flex-direction: column ;
}

.selectortitre{
  display: flex;
}

.selectorcorps{
  display: flex;
  flex-direction: row ;
}

.selectornom{
  display: flex;
}

.selectorinput{
  display: flex;
}

.selectorbouton{
  display: flex;
  flex-direction: column ;
}

.selectorligne{
  display: flex;
  flex-direction: row ;
} */

/* DROITE */

.border border-primary{
}



/* .gaucherectangle{
  font-weight: bold;
  display: flex;
  position: fixed;
  overflow: scroll;
  height: 91%;
  font-size: 13px;  
} */

.img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}


img.photocopy:hover {
  background-color: #466cad;
  width: 35px;
}

img.photocopy {
  width: 35px;
}

img.photocopy:hover:link { color: red; }

.barreduhaut{
  display: flex;
  flex-direction: row ;

}

p.titredroite{
  display: flex;
  justify-content: center ;
  align-items: center ;
  color: #1F3356;
  font-weight: bold;
  font-size: 120%;
  margin-left: 10px;
}

.zonerendutexte{
  min-width: 90%;
  max-width: 90%;
  min-height: 90%;
  max-height: 90%;
  margin-bottom: 4px;
  border: solid;
  border-color: #1F3356;
  background-color: white;
  border-width: 1px;
  color: black;
  line-height : 17px;
  padding-top: 15px;
  padding-left: 5px;
  white-space: Normal;
  overflow: scroll;
  font-weight: normal;

}

.grasetsouligne{
  font-weight: bold;
  text-decoration: underline;
}

.souligne{
  text-decoration: underline;
}


/* .TwoHeader{
  position: fixed;
} */